body {
  margin: 0;
  font-family: Delivery, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  font-feature-settings: normal;

  -webkit-font-feature-settings: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

hr {
  background: transparent;
  border: 1px #e8e8e8 solid;
  border-width: 1px 0 0 0;
}

.alert-banners div:last-child {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.ant-alert-banner {
  z-index: 100;
  font-size: 14px !important;
}

.ant-alert-banner button {
  font-size: 14px !important;
}


@font-face {
  font-family: 'Delivery';
  src: url('./assets/fonts/delivery_bd-webfont.woff2') format('woff2'),
    url('./assets/fonts/delivery_bd-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'deliverybold_italic';
  src: url('./assets/fonts/delivery_bdit-webfont.woff2') format('woff2'),
    url('./assets/fonts/delivery_bdit-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'deliverycondensed_black';
  src: url('./assets/fonts/delivery_cdblk-webfont.woff2') format('woff2'),
    url('./assets/fonts/delivery_cdblk-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'deliverycondensed_light';
  src: url('./assets/fonts/delivery_cdlt-webfont.woff2') format('woff2'),
    url('./assets/fonts/delivery_cdlt-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'deliveryitalic';
  src: url('./assets/fonts/delivery_it-webfont.woff2') format('woff2'),
    url('./assets/fonts/delivery_it-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'deliverylight';
  src: url('./assets/fonts/delivery_lt-webfont.woff2') format('woff2'),
    url('./assets/fonts/delivery_lt-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'deliverylight_italic';
  src: url('./assets/fonts/delivery_ltit-webfont.woff2') format('woff2'),
    url('./assets/fonts/delivery_ltit-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'deliveryregular';
  src: url('./assets/fonts/delivery_rg-webfont.woff2') format('woff2'),
    url('./assets/fonts/delivery_rg-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
