.logged-in-page {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  margin-top: 80px;
  max-width: 700px;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(119,118,115,0.30);
  padding: 30px 30px 60px;
}
.logged-in-page--wide {
  max-width: 1190px;
}

.logged-in-page__header {
  border-bottom: 1px #efefef solid;
  padding: 10px 0 0px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
.logged-in-page__page-title {
  padding: 16px 0;

  letter-spacing: -1;
  font-family: 'deliverycondensed_black';
}

.logged-in-page__page-title h2.ant-typography {
  font-weight: 900;

}

@media screen and (max-width: 600px) {
  .logged-in-page {
    width: 100%;
    padding: 0 20px;
  }

  .logged-in-page__page-title h2 {
    font-size: 24px !important;
  }
  .logged-in-page .locale-select {
    padding-top: 0;
  }
}
