.wrapper {
  display: flex;
  background-image: linear-gradient(180deg, #FECC00 0%, #F9EBB2 100%);
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 80px
}

.header {
  display: block;
  position: absolute;
  top: 20px;
  left: 40px;
}

.main {
  padding: 0 0 50px;
  width: 90%;
  max-width: 500px;
  padding: 48px;
  border-radius: 5px;
  display: block;
  border: 1px #ccc solid;
  background: white;
  box-shadow: 0 2px 10px 0 rgba(119,118,115,0.30);
  border-radius: 4px;
  height: 500px;
}
