.image-uploader {
  width: 100%;
  height: 348px;
  border: 1px solid #fecc00;
  border-radius: 4px;
  border-style: dashed;
  position: relative;
}

.image-uploader__preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image-uploader__dropzone {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.image-uploader__placeholder {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
}
.image-uploader__placeholder-text {
  font-size: 20px;
  font-family: deliveryregular;
}
.image-uploader__placeholder-btn {
  width: 230px;
  height: 44px;
  line-height: 44px;
  font-size: 16px;
  background: #FECC00;
  margin: 0 auto;
  border-radius: 4px 4px 4px 4px;
}