.card {
  background: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(119; 118, 115, 0.3);
  border-radius: 4px;
  transition: all 300ms ease;
  width: 33%;
  height: 265px;
  margin: 10px;
  text-align: center;
  color: #000000;
  position: relative;

}
.card:hover {
  box-shadow: 0 6px 30px 0 rgba(119, 118, 115, 0.5);
}

.content {
  z-index: 2;
  position: relative;
}

.img {
  margin-top: 40px;
  margin-bottom: 20px;
  width: 70%;
  max-width: 190px;
  max-height: 130px;
  object-fit: contain;
}

.title {
  font-family: deliverycondensed_black;
  font-size: 20px;
}
.description {
  font-family: Helvetica;
  font-size: 14px;
  padding: 0 10px;
}

.video {
  width: 100%;
  max-width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  left: 0;
  margin: 0;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  object-position: top middle;
  border-radius: 5px;
}