.sales-video-boxes {
    margin: 40px auto 0;
}

.sales-video-box {
    margin: 0 0 10px;
    padding: 50px 30px 30px;
    background: white;
    box-shadow: 0 2px 10px 0 rgba(119, 118, 115, 0.3);
    border-radius: 4px;
    position: relative;
}

.sales-video-box h1, h2 {
    color: #c22a23;
    text-transform: uppercase;
    letter-spacing: -1;
    font-family: 'deliverycondensed_black';
    font-size: 18px;
}

.sales-video-box h1 {
    color: white;
}


.sales-video-box--intro {
    padding-top: 50px;
    min-height: 600px;
    color: white;
    background-color: black;

}
.sales-video-box--sv {}
.sales-video-box--en {}


.sales-video-box--guide {
    padding-left: 45%;
}

.sales-video-box h1 {
    font-size: 30px;
}

.sales-video-box p {
    font-weight: 300;
    font-family: 'Helvetica';
}

.sales-video-box.sales-video-box--intro p { 
    font-family: 'Delivery';
}

.sales-video-box p strong {
    font-weight: 300;
    font-family: 'Delivery';
    font-size: 15px;
    color: #c22a23;

}
.sales-links {
    padding-top: 20px;
    padding-bottom: 0px
}

.sales-video-box__flag {
    position: absolute;
    top: 15px; 
    right: 25px;
    width: 30px;
}

.sales-video-box__guide-car {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; 
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top middle;
    opacity: 0.75;

    z-index: 1;
}

.sales-video-box__content {
    z-index: 2;
    position: relative;
}

.sales-video__video-preview {
    max-width: 100%;
}