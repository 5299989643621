.menuButton {
  float: right;
  margin: 20px 18px;
  cursor: pointer;
  transition: transform ease 100ms;
}
.menuButton:hover {
  transform: scale(1.1);
}
.menuButton:active {
  opacity: 0.85;
}
.menu {
  position: fixed;
  top: 88px;
  right: 15px;
  background: white;
  padding: 20px;
  padding-bottom: 0;
  text-align: left;
  border: 1px #ccc solid;
  border-radius: 4px;
}
.menu:after, .menu:before {
	bottom: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.menu:after {
	border-color: rgba(255, 255, 255, 0);
	border-bottom-color: #ffffff;
	border-width: 10px;
	margin-left: -10px;
}
.menu:before {
	border-color: rgba(255, 255, 255, 0);
	border-bottom-color: #ccc;
	border-width: 11px;
	margin-left: -11px;
}


.menuItem {
  border: none;
  display: block;
  cursor: pointer;
  padding: 4px 0;
  color: #000;
  font-family: 'Delivery';
  line-height: 22px;
}
.menuItem:hover {
  color: #c22a23;
}


.userProfileImage {

}

.userProfileImage img {
  width: 40px;
  height: 40px;
  display: block;
  border-radius: 50%;
  margin: 0 auto 0px;

}

.userName {
  text-align: center; 
  font-family: "deliveryregular";
  font-size: 11px;
  margin-bottom: 15px;
}